import React from 'react';
import PropTypes from 'prop-types';

const ChallengesSolutions = ({ challenges, solutions }) => {

return (
        <div>
            <div className="custom-medium-show desktop-view">
                <div className="slds-grid slds-wrap">
                    <div className="slds-size--1-of-2 slds-text-align--center title-large slds-m-bottom--medium">Challenges</div>
                    <div className="slds-size--1-of-2 slds-text-align--center title-large slds-m-bottom--medium">Solutions</div>
                    {
                        challenges.map((item, index) => {
                            const css_className = 'text-line '+ ((index%2 == 0) ? 'odd' : 'even');
                            if (index<=solutions.length){
                                return (
                                    <div key={index} className="slds-grid slds-wrap slds-grid--vertical-stretch">
                                        <div className="slds-size--1-of-2"><div className={css_className}><div className="text-container">{item}</div></div></div>
                                        <div className="slds-size--1-of-2"><div className={css_className}><div className="text-container">{solutions[index]}</div></div></div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            <div className="mobile-view">
                <div className="slds-grid slds-wrap">
                    <div className="slds-size--1-of-1 slds-medium-size--1-of-2">                    
                        <div className="slds-text-align--center title-large slds-m-bottom--medium">Challenges</div>
                            {
                                challenges.map((item, index) => {
                                    return (
                                        <div key={index} className="slds-grid slds-wrap slds-grid--vertical-stretch">
                                            <div className='text-line'><div className="text-container">{item}</div></div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                    <div className="slds-size--1-of-1 slds-medium-size--1-of-2">
                    <div className="slds-text-align--center title-large slds-m-bottom--medium slds-p-top--large">Solutions</div>
                        {
                            solutions.map((item, index) => {
                                return (
                                    <div key={index} className="slds-grid slds-wrap slds-grid--vertical-stretch">
                                        <div className='text-line'><div className="text-container">{item}</div></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

ChallengesSolutions.propTypes = {
    challenges: PropTypes.array.isRequired,
    solutions: PropTypes.array.isRequired
};
    
export default ChallengesSolutions;

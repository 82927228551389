import React from 'react';
const LearnMore = ({leanMoreLinks}) => {
    return (
        <div className="slds-grid slds-m-vertical--x-large">
            <div className="slds-size--1-of-1 slds-medium-size--1-of-2 slds-p-horizontal--medium cusp-customer-card-background_white cusp-learnMore">

                <div className="panel--white slds-p-horizontal--x-large slds-p-vertical--medium">
                    <div className="Fz(24) Fw(b)">
                        Learn more
                    </div>

                    <div className="Fz(18)">
                        {leanMoreLinks.map((item, index)=> (
                            <a className="D(b)" href={item.url} target="_blank" key={index}>{item.label}</a>
                        ))}                   
                            
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnMore;
import React from 'react';
import PropTypes from 'prop-types';


const Achievement = ({ achievements, achievementsAddColumns }) => {
  const length = achievements.length;
  let columnClass = 'slds-medium-size--1-of-3';
  let achievementElements = [];

  if (length === 2) {
    columnClass = 'slds-medium-size--1-of-2';
    if (achievementsAddColumns) {
      columnClass = 'slds-medium-size--2-of-6';
      achievementElements.push(
        <div className="slds-small-show slds-size--1-of-6">&nbsp;</div>
      );
    }
  }

  achievements.map((item, index) => {
    achievementElements.push(
      <div
        className={`slds-size--1-of-1 ${columnClass} slds-text-align--center slds-p-bottom--small slds-p-horizontal--small`}
        key={index}>
        <div className={`cusp-achievement-${item.badgeName}`}>&nbsp;</div>
        <div className="cusp-achievement-value">{item.value}</div>
        <div className="cusp-achievement-text">{item.text}</div>
      </div>
    );
  });

  if (length === 2 && achievementsAddColumns) {
    achievementElements.push(
      <div className="slds-small-show slds-size--1-of-6">&nbsp;</div>
    );
  }

  return (
    <div>
      <div className="slds-text-align--center cusp-achievement">
      Achievements
      </div>

      <div className="slds-wrap slds-grid slds-grid--pull-padded-small slds-m-top--large">
        {achievementElements}
      </div>
    </div>
  );
};

Achievement.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  achievementsAddColumns: PropTypes.bool.isRequired
};

export default Achievement;

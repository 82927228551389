import React from 'react';
import PropTypes from 'prop-types';


const ThUsage = ({ trailheadUsageText, companyRegion, companyIndustry, companyEmployees }) => {
    return (
        <div className="H(100%)">
            <div className=" cusp-thusage-bluebox slds-p-around--x-large slds-align--absolute-center">
                <div className="cusp-thusage-bluebox-alignMiddle">
                    <div className="slds-text-align--center cusp-thusage-bluebox_text">
                        {trailheadUsageText}
                    </div>

                    <div className="slds-text-align--center slds-m-top--large cusp-thusage-bluebox_region">
                        {
                            Boolean(companyRegion.length) && (
                                <>
                                <b>Region</b>: {companyRegion}<br />
                                </>
                            )
                        }
                        <b>Industry</b>: {companyIndustry}<br />
                        <b>Employees</b>: {companyEmployees}
                    </div>
                    </div>
            </div>
        </div>
    );
};

ThUsage.propTypes = {
    trailheadUsageText:  PropTypes.string.isRequired
};

export default ThUsage;
import React from 'react';

const StartLearning = () => {
    return (
        <div>
            <img className="" src="https://developer.salesforce.com/resources2/customer-stories/trailhead-logo.png" />
      
            <div className="Fz(24) slds-m-top--x-large">
                <div className="Fz(24) Ff(NexaRustBlack) Fw(b)">GET YOUR COMPANY STARTED ON THE PATH TO SUCCESS </div>
            </div>
            <a className="slds-button cusp-btn--orange slds-m-top--x-large" href="https://trailhead.salesforce.com/en/content/learn/modules/trailhead-for-companies" target="_blank">START LEARNING</a>
        </div>
    
    );
};

export default StartLearning;
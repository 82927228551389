import React from 'react';
import PropTypes from 'prop-types';

const QuoteHero = ({item}) => {
  const {quoteName, quoteImage, quoteText, quoteJobTitle, companyName, companyLogo} = item;

  return (
    <div className="cusp-quote-container cusp-customer-card-background_white">
      <div className="cusp-hero-logo">
        <img src={companyLogo} />
      </div>
      <div className="cusp-quote-text">
        “{quoteText}”
      </div>
      <div className="cusp-quote-footer">
        <b>{quoteName}</b><br/>
        {quoteJobTitle}, {companyName}
      </div>
      <div className="cusp-quote-image-container">
        <img
          className="cusp-quote-image"
          src={quoteImage} />
      </div>
    </div>
  );
};

QuoteHero.propTypes = {
  item: PropTypes.shape().isRequired
};

export default QuoteHero;


import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/Layout';
import QuoteHero from '../components/QuoteHero';
import ThUsage from '../components/ThUsage';
import Achievement from '../components/Achievement';
import ChallengesSolutions from '../components/ChallengesSolutions';
import LearnMore from '../components/LearnMore';
import StartLearning from '../components/StartLearning';
import ShareSocialMedia from '../components/ShareSocialMedia';

export const CustomerTemplate = item => {
    const {
        pageContext: {
            data,
            data:{
                slug,
                trailheadUsageText,
                quoteText,
                companyName,
                companyLogo,
                quoteName,
                quoteJobTitle,
                quoteImage,
                companyIndustry,
                companyRegion,
                companyEmployees,
                achievements,
                achievementsAddColumns,
                challenges,
                solutions,
                leanMoreLinks
            }
        },
    } = item;

    return (
      <Layout>
        <div className={`cusp-site cusp-details-page cusp-details-page-custom--${slug}`}>
          <div className="cusp-customer-customerbanner">
            &nbsp;
          </div>
          <div className="cusp-customer">
            <div className="slds-container--center cusp-customer-container slds-p-horizontal--small slds-p-bottom--x-large">
              <div className="cusp-customer-card-background_white">
                <QuoteHero item={data} />
              </div>
              <div className="slds-grid slds-wrap slds-grid--pull-padded-medium slds-m-top--x-large slds-grid--vertical-stretch">
                <div className="slds-size--1-of-1 slds-medium-size--1-of-2 slds-p-horizontal--medium cusp-customer-bluebox-padding">
                  <ThUsage
                    trailheadUsageText={trailheadUsageText}
                    companyRegion={companyRegion}
                    companyIndustry={companyIndustry}
                    companyEmployees={companyEmployees}
                  />
                </div>
                <div className="slds-size--1-of-1 slds-medium-size--1-of-2 slds-p-horizontal--medium">
                  <div className="cusp-customer-card-background_white h100% slds-p-around--large">
                    <Achievement achievements={achievements} achievementsAddColumns={achievementsAddColumns} />
                  </div>
                </div>
              </div>
              <div className="cusp-customer-card-background_white Pb(120) slds-m-vertical--x-large slds-p-top--x-large cusp-customer-custom-padding">
                <ChallengesSolutions challenges={challenges} solutions={solutions}/>
                {/* CHALLENGES / SOLUTIONS */}
              </div>
              {Boolean(leanMoreLinks.length) && (
                <LearnMore leanMoreLinks={leanMoreLinks} />
              )}
              <div className="cusp-customer-card-background_lightblue slds-text-align--center slds-p-around--xx-large slds-is-relative">
                {Boolean(leanMoreLinks.length) && (
                  <img className="cusp-customer-codey-overlay" src="https://developer.salesforce.com/resources2/customer-stories/Codey.png" />
                )}
                <StartLearning />
              </div>
            </div>
          </div>
          <ShareSocialMedia/>
          {
            /*
            <a title='Share on Facebook'
              target='_blank'
              href='http://www.facebook.com/sharer.php?t=https://developer.salesforce.com/customer-spotlights'>
                 Share on Facebook
            </a>
            <a className="twitter-share-button"
              target='_blank'
              href="https://twitter.com/intent/tweet?text=Customer Spotlights https://developer.salesforce.com/customer-spotlights">
              Tweet
            </a>
            */
          }
        </div>
      </Layout>
    );
};

CustomerTemplate.propTypes = {
  pageContext: PropTypes.shape().isRequired
};

export default CustomerTemplate;

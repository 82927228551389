import React from 'react';
import { Helmet } from "react-helmet";

const ShareSocialMedia = () => {
    return (
        <div>
            <div className="share-this-story-container">
                <div className="slds-p-vertical--x-large slds-text-align--center">
                    <div className="share-this-story-headline">
                        Share this story
                    </div>
                    <div className="addthis_inline_share_toolbox_cel7"></div> 
                    <Helmet>
                        <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5329fb0d17e1f4da"></script>
                    </Helmet>
                </div>
            </div>
        </div>
    );
}

export default ShareSocialMedia;